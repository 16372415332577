import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Footer from "../common/footer";
import Header from "../common/header";

import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import OceanClub1504WildDunes_1 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-1.jpg';
import OceanClub1504WildDunes_2 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-2.jpg';
import OceanClub1504WildDunes_3 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-3.jpg';
import OceanClub1504WildDunes_4 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-4.jpg';
import OceanClub1504WildDunes_5 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-5.jpg';
import OceanClub1504WildDunes_6 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-6.jpg';
import OceanClub1504WildDunes_7 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-7.jpg';
import OceanClub1504WildDunes_8 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-8.jpg';
import OceanClub1504WildDunes_9 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-9.jpg';
import OceanClub1504WildDunes_10 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-10.jpg';
import OceanClub1504WildDunes_11 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-11.jpg';
import OceanClub1504WildDunes_12 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-12.jpg';
import OceanClub1504WildDunes_13 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-13.jpg';
import OceanClub1504WildDunes_14 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-14.jpg';
import OceanClub1504WildDunes_15 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-15.jpg';
import OceanClub1504WildDunes_16 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-16.jpg';
import OceanClub1504WildDunes_17 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-17.jpg';
import OceanClub1504WildDunes_18 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-18.jpg';
import OceanClub1504WildDunes_19 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-19.jpg';
import OceanClub1504WildDunes_20 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-20.jpg';
import OceanClub1504WildDunes_21 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-21.jpg';
import OceanClub1504WildDunes_22 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-22.jpg';
import OceanClub1504WildDunes_23 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-23.jpg';
import OceanClub1504WildDunes_24 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-24.jpg';
import OceanClub1504WildDunes_25 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-25.jpg';
import OceanClub1504WildDunes_26 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-26.jpg';
import OceanClub1504WildDunes_27 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-27.jpg';
import OceanClub1504WildDunes_28 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-28.jpg';
import OceanClub1504WildDunes_29 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-29.jpg';
import OceanClub1504WildDunes_30 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-30.jpg';
import OceanClub1504WildDunes_31 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-31.jpg';
import OceanClub1504WildDunes_32 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-32.jpg';
import OceanClub1504WildDunes_33 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-33.jpg';
import OceanClub1504WildDunes_34 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-34.jpg';
import OceanClub1504WildDunes_35 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-35.jpg';
import OceanClub1504WildDunes_36 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-36.jpg';
import OceanClub1504WildDunes_37 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-37.jpg';
import OceanClub1504WildDunes_38 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-38.jpg';
import OceanClub1504WildDunes_39 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-39.jpg';
import OceanClub1504WildDunes_40 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-40.jpg';
import OceanClub1504WildDunes_41 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-41.jpg';
import OceanClub1504WildDunes_42 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-42.jpg';
import OceanClub1504WildDunes_43 from '../assets/image/1504OceanClubWildDunes/1504-Ground-Photo-Masters-43.jpg';


function OceanClub1504WildDunes() {


    var settings = {
        dots: true,
        //infinite: false,
        arrows: false,
        infinite: true,
        //speed: 5000,
        autoplaySpeed: 5000,
        //autoplay: true,
        autoplay: false,
        adaptiveHeight: false,
        slidesToShow: 1,
        pauseOnFocus: true,
        cssEase: 'ease',
        fade: true,
        pauseOnHover: true,
        swipeToSlide: true,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className="main_div">
            <Header />
            <div className="page-header-with-back-image-title-subtitle" style={{ backgroundImage: `url("${OceanClub1504WildDunes_38}")` }} >
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12} className="page-header-with-back-image-title-subtitle-col">
                            <div className="page-header-with-back-image-title-subtitle-inner">
                                <h1>1504 Ocean Club – Wild Dunes</h1>
                                <h3>START FROM <span>$415</span> <sub>/ NIGHT</sub></h3>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-properties-gallery-outer">
                <Container>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_1} image={OceanClub1504WildDunes_1} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_2} image={OceanClub1504WildDunes_2} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_3} image={OceanClub1504WildDunes_3} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_4} image={OceanClub1504WildDunes_4} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_5} image={OceanClub1504WildDunes_5} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_6} image={OceanClub1504WildDunes_6} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_7} image={OceanClub1504WildDunes_7} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_8} image={OceanClub1504WildDunes_8} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_9} image={OceanClub1504WildDunes_9} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_10} image={OceanClub1504WildDunes_10} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_11} image={OceanClub1504WildDunes_11} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_12} image={OceanClub1504WildDunes_12} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_13} image={OceanClub1504WildDunes_13} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_14} image={OceanClub1504WildDunes_14} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_15} image={OceanClub1504WildDunes_15} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_16} image={OceanClub1504WildDunes_16} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_17} image={OceanClub1504WildDunes_17} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_18} image={OceanClub1504WildDunes_18} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_19} image={OceanClub1504WildDunes_19} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_20} image={OceanClub1504WildDunes_20} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_21} image={OceanClub1504WildDunes_21} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_22} image={OceanClub1504WildDunes_22} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_23} image={OceanClub1504WildDunes_23} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_24} image={OceanClub1504WildDunes_24} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_25} image={OceanClub1504WildDunes_25} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_26} image={OceanClub1504WildDunes_26} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_27} image={OceanClub1504WildDunes_27} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_28} image={OceanClub1504WildDunes_28} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_29} image={OceanClub1504WildDunes_29} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_30} image={OceanClub1504WildDunes_30} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_31} image={OceanClub1504WildDunes_31} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_32} image={OceanClub1504WildDunes_32} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_33} image={OceanClub1504WildDunes_33} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_34} image={OceanClub1504WildDunes_34} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_35} image={OceanClub1504WildDunes_35} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_36} image={OceanClub1504WildDunes_36} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_37} image={OceanClub1504WildDunes_37} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_38} image={OceanClub1504WildDunes_38} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_39} image={OceanClub1504WildDunes_39} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_40} image={OceanClub1504WildDunes_40} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_41} image={OceanClub1504WildDunes_41} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_42} image={OceanClub1504WildDunes_42} /></div>
                    <div className="wilddunesallery_grid"> <ReactFancyBox thumbnail={OceanClub1504WildDunes_43} image={OceanClub1504WildDunes_43} /></div>
                </Container>
            </div>
            <div className="single-property-description">
                <div className="single-property-description-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-title-inner"><h4>Description Of Properties</h4></div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-description-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-description-content-inner">
                                    {/* <p>The Ocean Club Complex is considered to be the best oceanfront condos at Wild Dunes. Ours is the best of the best because of its placement in the building.</p>
                                    <p>Picture in your mind a virtual stage. The image I will present is important because it illustrates how we define ‘oceanfront’, and how our meaning may be different from others. As you are entering our villa – translated expensive condo – from a large outside hallway, the ocean is not yet a part of the panorama because it is hidden from view on the back side of the building. You walk into a foyer and turn right down a hallway, and as you are walking you see a mother and her ten year old daughter approaching. The hall opens up into the dining/living room area, and one wall suddenly turns into floor to ceiling windows. The glass vista of the ocean continues approximately 30 feet down the whole side of the villa, and as you turn onto another angle, the view continues for another 20 feet. The entire building angles toward the beach and is strategically located so that from all the windows you see swelling waves and pristine beach. To conclude the virtual tale, as the mother and daughter enter the room, the little girl slowly looks around, then turns to her mother and says, “Momma, are we on a boat?”</p>
                                    <p>Let there be no doubt – if you are looking for an ocean front view, there is no comparison between our villa and others that are available. There is an ocean front view from the dining/living room and ALL three of the bedrooms. The only rooms in this huge villa (2300+ square feet, 9 foot ceilings) without the view are the laundry room and half bath. There are additional interesting items concerning this rental that I will furnish you when you email me for additional information. There are fifty pictures on the website. The max allowed by VRBO. I’m going to email you a video. It helps to link together all the still pictures. As they say ‘a picture is worth a thousand words’. The frosting on the cake is the extensive balconies that come off the three bedrooms and living/dining room highlighted with high quality furniture. Heaven should be so nice.</p>
                                    <p>I will be providing you with other links to about ten properties in Ocean Club. It is a complex consisting of two buildings each containing 51 villas. The property sites I’m sending you all have owners that are a little better about ‘meat and potatoes’ than I. Please check their rental rate so you can see how ours compares favorably. Visiting these other sites will be very helpful in your understanding of the Ocean Club/Wild Dunes properties in particular and Charleston in general. There is a lot of information available to those interested in this beautiful area of South Carolina beaches. However, it is most important that you try and picture their view of the ocean in comparison to ours. All of the villas have similar floor plans, furnished as you would expect luxury villas should be. We offer the one big differences – our location maximizes the view of the ocean.</p>
                                    <p>Two other factors to consider in our rental are housekeeping and maintenance. We are fortunate to have two of the best. Maintenance is available to you 24/7. We will provide you with the phone number. Should anything not work properly, call. In the unlikely event you are not pleased with the cleanliness of the villa when you arrive, call housekeeping to come back in and clean it to your satisfaction. We will provide you with that number as well. There is a lot of additional information to email so you can make absolutely certain we will meet your needs. As you well know planning the perfect vacation takes good research. You can always rest when you get here. Check the calendar to see about availability for the dates you want and carefully read about the rental rates. The rates are current and are not subject change upward.</p>
                                    <p>I usually respond to an inquiry within 24 hours of receipt. If you haven’t heard from me within that frame period, check the calendar for availability. I usually don’t respond if we don’t have availability. Next try your spam. Sometimes I get caught there. Last but not least call me (Peter)</p>
                                    <p>HIGHLIGHTS IN REVIEW: 1) If you are requesting a lower price than our posted rate, then we need the web link to the lower priced one you are considering. 2) Check calendar for availability before submitting an inquiry. 3) Review the 50 pictures and video. 4) Read comments by past guests. 5) Thank you so much for considering our place.</p>
                                    <p>FYI: As owners and managers we have an awesome responsibility….your vacation. It has got to be as perfect as we can make it. For many people it is the biggest event of the year. On our web site we hit the highlights. It is your responsibility to narrow down your search. We hope we will end up in the top two or three. We have more information to share with you that hopefully will increase the chances on making a good choice whether you book our place or not . Click on: “Ask Owner/Manager a Question” As soon as I hear from you I will do a tentative block of the dates. I will respond not only answering questions but sending detailed information.</p>
                                    <p>One of our local renters is a professional photographer.  If you have need of her services, please click on: www.jessicaclaire.net. Mention you saw her name on this web site to get a discount.</p> */}
                                    <p>The Ocean Club Complex are the best oceanfront condos in the Wild Dunes Resort. Ours is the best of the best because of its placement in the building; our location maximizes the view of the ocean.  Plus, this villa is huge, 2,300 sq. ft. with 9-foot ceilings!</p>
                                    <p>There is nothing better, first thing in the morning, than getting a cup of coffee or tea, sitting on the wrap around balcony, breathing the ocean air, feeling the sun on your face, watching the ocean birds, and the dolphins leaping in the ocean. It is paradise!  </p>
                                    <p>Let there be no doubt, if you are looking for an ocean front view, there is no comparison between our villa and the others that are available. There is an ocean front view from every room except a half bathroom and the laundry room.  In addition, our maintenance and cleaning crews are amazing but if you find something that is not quite right, we will provide you with contact information so you can get satisfaction ASAP!</p>
                                    <p>As property managers, we realize that we have an awesome responsibility; your vacation. Studies show that Americans are vacation deprived and not taking enough time for themselves or their families. A vacation getaway could be the perfect prescription for job burnout. You need and deserve the time off, take it. Your vacation must be as perfect and as meaningful as we can make it.  </p>
                                    <p>One of our local renters is a professional photographer.  If you have need of her services, please click on: www.jessicaclaire.net. Mention you saw her name on this web site to get a discount.</p>
                                    <p>Please contact us with any questions and book this amazing villa today!</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="single-property-video">
                <Container>
                    <Row>
                        <Col lg={12} md={12} sm={12}>
                            <iframe title="1504 Ocean Club" width="100%" height="686" src="https://www.youtube.com/embed/xECY7JzwKlU?feature=oembed" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="single-property-amenities">
                <div className="single-property-amenities-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-amenities-title-inner mb-4"><h4>Highlights</h4></div>
                            </Col>
                        </Row>
                    </Container>
                    <Container>
                        <div className="single-property-amenities-content-inner-content">
                            <Row>
                                <Col lg={4} md={4} sm={12}><ul><li>Property Type: Villa</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Area: 2,300 Square Feet</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-in: 4:00 PM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Check-out: 11:00 AM</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Max. Occupancy: 6</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Children Welcome</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Parties/Events Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Pets Not Allowed</li></ul></Col>
                                <Col lg={4} md={4} sm={12}><ul><li>Non Smoking Only</li></ul></Col>
                            </Row>
                            <Row>
                                <Col lg={12} md={12} sm={12}><ul><li>Location Type: Beach, Resort, Beachfront, Golf Course Front</li></ul></Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="single-property-amenities-content">
                    <Container>
                        <Row>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Unit And Ocean Club</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>3&nbsp; Bedrooms, Sleeps 6</li>
                                            <li>3.5 Bathrooms</li>
                                            <li>1 King Sized Bed, 1 Queen Sized Bed</li>
                                            <li>2 Twin Or Single Beds</li>
                                            <li>Jetted Tub In Master Bath</li>
                                            <li>Satellite, Cable TV, DVD Player, Wireless Internet</li>
                                            <li>VCR, Stereo, CD Player</li>
                                            <li>Huge Deck/Balcony Space</li>
                                            <li>Heating and AC </li>
                                            <li>Hair Dryer, Paper Towels, Toilet Paper </li>
                                            <li>Washer and Dryer, Ironing Board </li>
                                            <li>Ceiling Fans, Fireplace </li>
                                            <li>Oven, Stove, Dishwasher, Refrigerator, Microwave</li>
                                            <li>Grill</li>
                                            <li>Telephone</li>
                                            <li>Blender, Toaster, Coffee Maker </li>
                                            <li>Dishes And Cooking Utensils </li>
                                            <li>Spacious Dining Room</li>
                                            <li>Towels And Linens Provided </li>
                                            <li>Elevator And Private Parking</li>
                                            <li>Outdoor Charcoal Grill</li>
                                            <li>Outdoor Pool At Ocean Club</li>
                                            <li>Shared Outdoor Hot Tub</li>
                                            <li>Walkway To The Beach</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="single-property-amenities-content-inner">
                                    <div className="single-property-amenities-content-inner-title"><h6>Amenities Wild Dunes</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>2 – 18 Hole Championship Golf Courses</li>
                                            <li>Miles of Bike/Running Paths</li>
                                            <li>Shopping And Restaurants In Community</li>
                                            <li>Gated And Private Access </li>
                                            <li>Basketball Court and Playgrounds </li>
                                            <li>Marina, Trailer Parking, Boat Launch</li>
                                        </ul>
                                    </div>
                                    <div className="single-property-amenities-content-inner-title"><h6>Things To Do Charleston</h6></div>
                                    <div className="single-property-amenities-content-inner-content">
                                        <ul>
                                            <li>Many Amazing Shops And Restaurants</li>
                                            <li>Historical Carriage Tours</li>
                                            <li>Wine Tours</li>
                                            <li>Scenic Drives And Wildlife Viewing</li>
                                            <li>Horseback Riding</li>
                                            <li>Parasailing</li>
                                            <li>Deep Sea Fishing</li>
                                            <li>Speed Boat Adventures And Ghost Tours</li>
                                            <li>High Ropes Course Adventure</li>
                                            <li>Shem Creek Bar And Restaurant Tour</li>
                                            <li>Gibbes Museum Of Art</li>
                                            <li>Haunted Jail Tour</li>
                                            <li>Fort Sumter Tours</li>
                                            <li>South Carolina Aquarium</li>
                                            <li>Patriots Point Naval And Maritime Museum</li>
                                            <li>Mount Pleasant and Holly Beach Piers</li>
                                            <li>Whirlin’ Waters Adventure Waterpark</li>
                                            <li>Isle Of Palms County Park </li>
                                            <li>Splash Island Waterpark</li>
                                        </ul>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div >
            <div className="single-property-testimonials">
                <div className="single-property-testimonials-title">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-title-inner">
                                    <h4>Testimonials</h4>
                                    <p>What People Say About Us</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="single-property-testimonials-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <div className="single-property-testimonials-content-inner">
                                    <div className="single-property-testimonials-slider">
                                        <Slider className="single-property-testimonials-slider" {...settings} >
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>First, I must give a shout-out to the condo owner and property manager. When Irma threatened our vacation, initially scheduled a month earlier, they worked with me to reschedule our visit without charge. The kindness they showed was very much appreciated. We fell in love with this condo the moment we walked in the door. Fantastic views, wraparound balcony, beautifully decorated, sparkling clean and had EVERYTHING we need. Beach reading? Check. Sand toys for our 4-year-old? Check. All the kitchen implements we might need to make dinner? Check. We had Grandma along, too, and she said it was the nicest place she’s ever stayed. I can’t say enough good things. We’re already talking about staying here again. Thank you so much for sharing your beautiful slice of paradise with us.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Ashley H.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Having never been to Isle of Palms before, I wasn’t sure if this place was really so much better than the others during my online search weeks in advance of the visit. The moment we walked through the door, it was clear to me — this place really is nearly one of a kind. The layout of the condo, the fully-stocked kitchen, having a full bath with each of the three bedrooms, plus an added 1/2 bath were well done and would of themselves warrant a good review. The views from this condo — including the full balcony — are really breathtaking. I watched the sun rise from my bed one morning, and then joined my friends on the main balcony on the other mornings to enjoy the sunrise. I don’t want to overhype the view … but be assured, you will be quite pleased with your stay here.</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Matthew B.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>This condo is wonderful! It’s very spacious & decorated beautifully. We were there with our older daughter, our younger daughter, her husband & their baby. Everyone had their own BR & BA & had plenty of room to spread out. The beds were well appointed & comfy. The kitchen was well equipped w/lots of nice dinnerware & glassware. We love Isle of Palms & Charleston, & will definitely rent this again. My husband was concerned about beach erosion b/c we had been to Wild Dunes before (not this condo) when there was virtually NO beach. Although at high tide this condo overlooks the water (which is really cool), there is plenty of beach right next to the building. I would definitely recommend this to anyone!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Mimi, Memphis, TN</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>Just as promised, the view was amazing! My family and I thoroughly enjoyed our week in this beautiful condo. The kitchen was well stocked, the décor was wonderful, and planning the trip was a breeze. Peter made everything so easy. It almost felt like we had our own private beach. Plus, the beach area, pool area, etc. was so nice and clean! It was very easy to relax. Thanks to Peter and the owners for sharing this wonderful condo!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Happy Mom, Johnson City, TN</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="slide">
                                                <div className="single-property-testimonials-slider-inner clearfix clear">
                                                    <div className="single-property-testimonials-slider-inner-title-info">
                                                        <div className="single-property-testimonials-slider-inner-info"><p>We just returned from having a fantastic week at this unforgettable condo! The views of the ocean are breathtaking from every room with gorgeous and luxurious décor. The large balcony offers you extended views of the ocean and a gracious place to have a cup of coffee in the morning and a nice drink in the evening! Highly recommend this condo to anyone wanting a vacation to remember!! Peter was right in that no other condo can compare with the location and special touches that this condo offers!</p></div>
                                                        <div className="single-property-testimonials-slider-inner-title"><h4>Debbie G.</h4></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Slider>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>

                <div className="single-property-price-content">
                    <Container>
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <h3 className="mb-3">Rates</h3>
                                <p className="left">Three days minimum required from September through May. Only rents by the week in the summer, Saturday to Saturday. Thanksgiving (11/18-11/26), Christmas/New Year’s (12/15-1/2) have an extra $200 fee. Rent a month between Jan 1st and Mar 15 for $6447 (there is a 6-week limit). SC, City and County Accommodation Tax 14% (We Have Already Added This into Your Booking When You Book). Cleaning/sheets/towels and bed making add $275 to the cost. Cancellation Policy: There is a $75 fee. The rest of the rental money is returned assuming we can re-rent.</p>
                                <p>SUMMER PRICES ARE SHOWN AS WEEKLY RATES</p>
                                <p>Summer:  Base Cost $4,587.00 TAXES: $612.00 Cleaning: $275.00 TOTAL: $5,474.00</p>
                                <p>OFF-SEASON PRICES SHOWN ARE PER NIGHT</p>
                                <p>Spring: Nightly Rate: $444 Taxes: $62 Total: $506*</p>
                                <p>Fall: Nightly Rate: $417 Taxes $58 Total: $475*</p>
                                <p>Winter:  Nightly Rate $377 Taxes $53 Total: $430*</p>
                                <p>* Plus $275 for cleaning</p>

                                <h3 className="mt-5">Making a Reservation</h3>
                                <h6 className="mt-2">It is imperative that the information we give you is YOUR full rental cost and availability is tailored to YOUR particular wishes and up to date with our present schedule of bookings.</h6>
                                <h6 className="mt-2">Email me at: <a href="mailto:coleresortrentals@gmail.com">coleresortrentals@gmail.com</a>, writing down the rental location you want to go to and the dates you would like. We will be back in touch ASAP.</h6>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default OceanClub1504WildDunes;